/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import '@fortawesome/fontawesome-free/css/all.css';
import 'bulma/css/bulma.css';
import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.css';
import './src/assets/sass/main.scss';
import { AuthProvider } from './src/auth';

export const wrapRootElement = ({ element }) => {
    return (
      <AuthProvider>{element}</AuthProvider>
    );
  }