import { navigate, useLocation } from '@reach/router';
import axios from 'axios';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { BACKEND_URL } from './constants';

/* const pageRegistry: { [key: string]: string[] } = {};

export function registerPage(path: string, roles: API.UserType[]) {
  if (pageRegistry[path]) {
    throw new Error(`Path ${path} was already registered.`);
  }
  pageRegistry[path] = [...roles];
} */

const AuthContext = createContext<ProvideAuthHook | undefined>(undefined);

export function AuthProvider({ children }: { children: any }) {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export function useAuth<T extends API.User>() {
  const context = useContext(AuthContext);
  if (!context) {
    return {};
  }

  return { ...context, user: context.user ? (context.user as T) : undefined };
}

type ProvideAuthHook = {
  loading: boolean;
  user: API.User | undefined;
  refresh: () => void;
  login: (email: string, password: string) => Promise<any>;
  register: (data: any) => void;
  logout: () => Promise<any>;
  verifyAndLogin: (token: string) => Promise<void>;
  requestResetPassword: (email: string) => Promise<boolean>;
  resetPassword: (token: string, password: string) => Promise<boolean>;
};
function useProvideAuth<T extends API.User>(): ProvideAuthHook {
  const [user, setUser] = useState<T | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  const login = (email: string, password: string) => {
    if (!email || !password) {
      return new Promise(resolve => false);
    }
    setLoading(true);
    return axios
      .post(
        `${BACKEND_URL}/auth/login`,
        { email, password },
        { withCredentials: true }
      )
      .then(r => {
        if (r.status === 200) {
          setUser(r.data);
          setLoading(false);
          return r.data as API.User;
        }
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        return false;
      });
  };

  const register = async (data: any) => {
    try {
      setLoading(true);
      const response = await axios
        .post<string>(`${BACKEND_URL}/auth/register`, data)
        .then(response => {
          if (response.status !== 200) {
            setLoading(false);
            throw new Error('Unbekannter Fehler.');
          }
          setLoading(false);
        })
        .catch(e => {
          setLoading(false);
          throw new Error(e.response.data.message);
        });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const logout = () => {
    return axios
      .post(`${BACKEND_URL}/auth/logout`)
      .then(r => {
        setUser(undefined);
        setLoading(false);
        return true;
      })
      .catch(e => {
        setUser(undefined);
        setLoading(false);
        return false;
      });
  };

  const requestResetPassword = async (email: string) => {
    try {
      const response = await axios.post<string>(
        `${BACKEND_URL}/auth/requestPasswordReset`,
        {
          email
        }
      );

      if (response.status === 201) {
        return true;
      }
      return false;
    } catch (error) {
      throw error;
    }
  };

  const resetPassword = async (token: string, password: string) => {
    try {
      const response = await axios.post<string>(
        `${BACKEND_URL}/auth/resetPassword`,
        {
          token,
          password
        }
      );

      if (response.status === 201) {
        return true;
      }
      return false;
    } catch (error) {
      throw error;
    }
  };

  const verifyAndLogin = async (verificationToken: string) => {
    try {
      setLoading(true);
      const response = await axios.post(`${BACKEND_URL}/auth/verify`, {
        verificationToken
      });

      if (response.status === 201) {
        setUser(response.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const refresh = useCallback(() => {
    setLoading(true);
    axios
      .get(`${BACKEND_URL}/auth/profile`, { withCredentials: true })
      .then(r => {
        if (r.status === 200) {
          setUser(r.data);
          setLoading(false);
          return;
        }
        setUser(undefined);
        setLoading(false);
      })
      .catch(e => {
        setUser(undefined);
        setLoading(false);
      });
  }, []);

  useEffect(refresh, []);

  return {
    refresh,
    loading,
    user,
    login,
    register,
    logout,
    verifyAndLogin,
    requestResetPassword,
    resetPassword
  };
}

export function useAuthGuard() {
  const { user, logout, loading } = useAuth();
  const location = useLocation();

  const publicPages = [
    '/',
    '/login',
    '/logout',
    '/dokumentation',
    '/download',
    '/register',
    '/datenschutz',
    '/impressum'
  ];

  if (!loading && !user) {
    if (
      !publicPages.includes(location.pathname) &&
      !publicPages.includes(
        location.pathname.substr(0, location.pathname.length - 1)
      )
    ) {
      navigate('/login');
    }
  }

  return {
    loading
  };
}
